@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Roboto:wght@400;700&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Source Code Pro", Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  position: sticky;
  top: 0;
  overflow: hidden;
  z-index: 50; /* Ensure it stays on top */
}

.navbar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1); /* Increase the opacity for better contrast */
  z-index: 1; /* Ensure it covers the video */
}

.navbar-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom; /* Display the lower part of the video */
  z-index: 0; /* Ensure it's behind everything */
}

.navbar a,
.navbar button {
  font-weight: bold; /* Increase font weight */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Add a subtle text shadow */
}

/* Define keyframes for background color animation with varied gradient stops */
@keyframes backgroundAnimation {
  0% {
    background: linear-gradient(
      45deg,
      #a8dadc,
      #f1faee 25%,
      #a8dadc 50%,
      #f1faee 75%,
      #a8dadc
    );
  }
  25% {
    background: linear-gradient(
      45deg,
      #f1faee,
      #ffddd2 25%,
      #f1faee 50%,
      #ffddd2 75%,
      #f1faee
    );
  }
  50% {
    background: linear-gradient(
      45deg,
      #ffddd2,
      #faf3dd 25%,
      #ffddd2 50%,
      #faf3dd 75%,
      #ffddd2
    );
  }
  75% {
    background: linear-gradient(
      45deg,
      #faf3dd,
      #a8dadc 25%,
      #faf3dd 50%,
      #a8dadc 75%,
      #faf3dd
    );
  }
  100% {
    background: linear-gradient(
      45deg,
      #a8dadc,
      #f1faee 25%,
      #a8dadc 50%,
      #f1faee 75%,
      #a8dadc
    );
  }
}

/* Apply the animation to the header */
.header-bg-animation {
  animation: backgroundAnimation 10s linear infinite;
}

/* Define keyframes for text color animation */
@keyframes textColorAnimation {
  0% {
    color: #1d3557; /* Dark Blue */
  }
  25% {
    color: #457b9d; /* Light Blue */
  }
  50% {
    color: #e63946; /* Light Peach */
  }
  75% {
    color: #a8dadc; /* Light Mint */
  }
  100% {
    color: #1d3557; /* Dark Blue */
  }
}

/* Apply the animation to the header text */
.header-text-animation {
  animation: textColorAnimation 10s linear infinite;
}
